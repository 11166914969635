.container {
    width: 90%;
    margin: auto;
    margin-top: 20px;
}

.communities .ui.move.reveal {
    white-space: normal !important;
    line-height: normal !important;
}

.ui.horizontal.divider {
    margin-top: 40px;
    margin-bottom: 20px;
}

.videowrapper {
    float: none;
    clear: both;
    width: 100%;
    position: static;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

.videowrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.normal-video {
    width: 100%;
}

@media only screen and (max-width: 414px) {
    .ui.horizontal.divider {
        white-space: normal;
    }
}

@media only screen and (min-width: 992px) {
    .static-video {
        bottom: 10px;
        right: 10px;
        width: 200px;
        z-index: 10000;
        border: 10px solid black;
        position: fixed;
    }
}